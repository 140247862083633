<template>

  <div class="page-container bg-white py-4 px-5">
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
      <el-form-item label="Name" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getCurrentUser} from "@/API/UsersAPIs";

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        password: '',
        email_verified: false,
      },
      rules: {
        name: [
          {required: true, message: 'Please enter the name', trigger: 'blur'}
        ],
        email: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email address', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: 'Please enter the phone', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email address', trigger: 'blur'}
        ],
        password: [
          {message: 'Please enter the password', trigger: 'blur'},
          {min: 8, message: 'Password must be at least 8 characters', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  async mounted() {
    var user = await getCurrentUser();
    this.form.name = user.name;
    this.form.email = user.email;
    this.form.phone = user.phone;
    this.form.email_verified = Boolean(user.email_verified);
  }
}
</script>

<style>
.demo-ruleForm {
  max-width: 500px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: '';
}
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:after,
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
  margin-left: 4px;
}
.el-form-item__label{
  text-align: start;
}
</style>
